import React from "react";
import SectionTitle from "./SectionTitle";
import SingleProduct from "./SingleProduct";
import { FormattedMessage } from "react-intl";

function Products({ data }) {
  return (
    <div
      id="products"
      className="relative my-20 mb-36 bg-gradient-to-b from-white to-[#09595a80] py-20 shadow-xl"
    >
      <SectionTitle title={<FormattedMessage id="Products" />} />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 container mx-auto mt-10 gap-10 ">
        {data.products.map((el, index) => {
          return <SingleProduct data={el} key={index} />;
        })}
      </div>
      <a
        href={data.url}
        target="_blank"
        className="bg-main-color absolute text-white rounded-3xl bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 p-5 py-3"
      >
        <p className="text-center">
          {<FormattedMessage id="download_catalog" />}
        </p>
      </a>
    </div>
  );
}

export default Products;

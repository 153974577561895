import React, { forwardRef, useState } from "react";
import { motion } from "framer-motion";
import { Tooltip } from "react-tooltip";
import { IoCloseSharp } from "react-icons/io5";

const ActiveMobileGoal = forwardRef(({  active , setActive }, ref) => {
  const selectedStyle = active
    ? "scale-100"
    : "";
  return (
    <motion.div
      // onClick={() => setActive(null)}
      className={`${selectedStyle} shadow-md sm:hidden h-full w-full scale-0 sc  transition-all duration-500 child z-50 absolute flex flex-col justify-center items-center gap-3  p-3 border-2 bg-main-color  rounded-full cursor-pointer  text-white `}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8, delay: 1 }}
      viewport={{ once: true, root: ref.current }}
    >
      <button
        onClick={() => setActive(null)}
        className="border bg-main-color h-10 w-10 absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 md:translate-x-0 md:translate-y-0  md:static flex justify-center items-center rounded-full"
      >
        <IoCloseSharp />
      </button>
      <p className=" text-center text-sm">{active?.desc}</p>
    </motion.div>
  );
});

export default ActiveMobileGoal;

import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useLanguage } from "../context/langContext";

function SectionSide({ reverse, img, title, description }) {
  const scrollRef = useRef(null);
  const { language } = useLanguage();

  return (
    <div
      ref={scrollRef}
      className={`w-full flex ${
        reverse ? "justify-end ps-5" : "justify-start pe-5"
      } items-center`}
    >
      <div
        className={`relative p-7 px-10 w-full md:w-4/5 lg:w-2/3 flex flex-col justify-center items-center sm:justify-start sm:items-center gap-12 ${
          reverse
            ? "sm:flex-row rounded-s-[50px]"
            : "sm:flex-row-reverse rounded-e-[50px]"
        } `}
      >
        <motion.div
          initial={{
            width: 0,
            // borderTopRightRadius: 0,
            // borderTopLeftRadius: 0,
            // borderBottomRightRadius: 0,
            // borderBottomLeftRadius: 0,
          }}
          whileInView={{
            width: "100%",
            // borderTopRightRadius: reverse ? (language == "en" ? 0 : "50px") : 0,
            // borderBottomRightRadius: reverse
            //   ? language == "en"
            //     ? 0
            //     : "50px"
            //   : 0,
            // borderTopLeftRadius: reverse
            //   ? language == "en"
            //     ? "50px"
            //     : "0px"
            //   : "50px",
            // borderBottomLeftRadius: reverse
            //   ? language == "en"
            //     ? "50px"
            //     : "0px"
            //   : "50px",
          }}
          transition={{ type: "spring", duration: 0.8, delay: 0.5 }}
          viewport={{ once: true }}
          className={`-z-10 absolute h-full ${
            reverse ? "start-0 rounded-s-[50px]" : "end-0 rounded-e-[50px]"
          }
           bg-[rgba(9,89,90,0.10)]`}
        ></motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 2 }}
          whileInView={{ opacity: 1, scale: 1.5 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className={`relative mx-3 flex justify-center items-center min-w-20 overflow-visible min-h-20 bg-main-color p-3 rounded-full `}
        >
          <motion.img
            initial={{
              opacity: 0,
              x: 100,
              scale: 1.2,
              rotateY: language == "ar" && 180,
            }}
            whileInView={{ opacity: 1, x: 0, scale: 1.4 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            src={img}
            alt=""
            className={` ${!reverse ? "w-10" : "absolute w-[300px]"} ${
              language == "ar" ? "" : ""
            }`}
          />
        </motion.div>
        <div
          className={`flex flex-col justify-center items-center ${
            reverse ? "sm:items-start" : "sm:items-end"
          }`}
        >
          <motion.h3
            initial={{ opacity: 0, y: -30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 0.8, delay: 0.7 }}
            viewport={{ once: true }}
            className="text-2xl w-full lg:w-1/2 flex justify-center items-center sm:justify-start"
          >
            <p className="pb-1 w-fit border-b-4 border-b-main-color">{title}</p>
          </motion.h3>
          <motion.p
            initial={{ opacity: 0, y: -30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 0.8, delay: 1 }}
            viewport={{ once: true }}
            className={`font-light w-full lg:w-1/2 text-center sm:text-start  mt-3`}
          >
            {description}
          </motion.p>
        </div>
      </div>
    </div>
  );
}

export default SectionSide;

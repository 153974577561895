import React from "react";
import { motion } from "framer-motion";

function SectionTitle({ title }) {
  return (
    <motion.div
      initial={{ opacity: 0, scaleX: 2 }}
      whileInView={{ opacity: 1, scaleX: 1 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
      className="w-full flex justify-center items-center"
    >
      <h3 className="pb-2 text-3xl font-bold border-b-4 border-b-main-color w-fit">
        {title}
      </h3>
    </motion.div>
  );
}

export default SectionTitle;

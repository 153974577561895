import React, { forwardRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import axios from "axios";
import toast from "react-hot-toast";
import { FaSpinner } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../context/langContext";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required(
    <FormattedMessage id="First_Name_is_required" />
  ),
  last_name: Yup.string().required(
    <FormattedMessage id="Last_Name_is_required" />
  ),
  email: Yup.string()
    .email(<FormattedMessage id="Invalid_email" />)
    .required(<FormattedMessage id="Email_is_required" />),
  message: Yup.string().required(<FormattedMessage id="Message_is_required" />),
});

const ContactForm = forwardRef(({}, ref) => {
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    message: "",
  };
  const { language, changeLanguage } = useLanguage();

  const handleSubmit = (values, { resetForm }) => {
    axios
      .post("https://admin-is-trator.alhazmie.com/api/contact-us", values, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then((res) => {
        toast.success(res.data.message || "Successfully toasted!");
        resetForm();
      })
      .catch((err) => {
        toast.error(err.response.data.message || "Successfully toasted!");
      });
  };

  return (
    <>
      <style>{`
		.error{
			color: rgb(248, 113, 113);
			font-size: 12px;
			font-weight: 100;
		}
		`}</style>
      <div className="h-full flex flex-col justify-around items-center">
        <h3 className="text-3xl font-bold text-white">
          {" "}
          <FormattedMessage id="contact-us" />{" "}
        </h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="w-full container flex flex-col gap-5">
              <div className="flex flex-col gap-0 xl:flex-row xl:justify-between xl:items-center xl:gap-5">
                <motion.div
                  initial={{ opacity: 0, scaleX: 1.2 }}
                  whileInView={{
                    opacity: 1,
                    scaleX: 1,
                    transition: { type: "spring", delay: 1.7 },
                  }}
                  viewport={{ once: true }}
                  className="flex flex-col"
                >
                  <label
                    className="text-white font-semibold mb-1"
                    htmlFor="first_name"
                  >
                    <FormattedMessage id="first-name" />
                  </label>
                  <Field
                    className="h-10 rounded-lg border-none outline-none p-2 text-main-color"
                    type="text"
                    name="first_name"
                  />
                  <div className="h-5">
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="error"
                    />
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scaleX: 1.2 }}
                  whileInView={{
                    opacity: 1,
                    scaleX: 1,
                    transition: { type: "spring", delay: 1.7 },
                  }}
                  viewport={{ once: true }}
                  className="flex flex-col"
                >
                  <label
                    className="text-white font-semibold mb-1"
                    htmlFor="last_name"
                  >
                    <FormattedMessage id="last-name" />
                  </label>
                  <Field
                    className="h-10 rounded-lg border-none outline-none p-2 text-main-color"
                    type="text"
                    name="last_name"
                  />

                  <div className="h-5">
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="error"
                    />
                  </div>
                </motion.div>
              </div>
              <motion.div
                initial={{ opacity: 0, scaleX: 1.2 }}
                whileInView={{
                  opacity: 1,
                  scaleX: 1,
                  transition: { type: "spring", delay: 1.7 },
                }}
                viewport={{ once: true }}
                className="flex flex-col"
              >
                <label
                  className="text-white font-semibold mb-1"
                  htmlFor="email"
                >
                  <FormattedMessage id="e-mail" />
                </label>
                <Field
                  className="h-10 rounded-lg border-none outline-none p-2 text-main-color"
                  type="email"
                  name="email"
                />
                <div className="h-5">
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, scaleX: 1.2 }}
                whileInView={{
                  opacity: 1,
                  scaleX: 1,
                  transition: { type: "spring", delay: 1.7 },
                }}
                viewport={{ once: true }}
                className="flex flex-col"
              >
                <label
                  className="text-white font-semibold mb-1"
                  htmlFor="message"
                >
                  <FormattedMessage id="contact-message" />
                </label>
                <Field
                  className="rounded-lg h-[170px] max-h-[170px] border-none outline-none p-2 text-main-color"
                  as="textarea"
                  name="message"
                />
                <div className="h-5">
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="error"
                  />
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, scaleX: 1.2 }}
                whileInView={{
                  opacity: 1,
                  scaleX: 1,
                  transition: { type: "spring", delay: 1.7 },
                }}
                viewport={{ once: true }}
                className="flex justify-end items-center"
              >
                <button
                  className="relative flex justify-center items-center gap-2 bg-white text-main-color px-6 py-2 w-28 rounded-lg hover:bg-cyan-400"
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="send" />
                  <div
                    className={`${
                      isSubmitting
                        ? "opacity-100 static"
                        : "absolute  opacity-0"
                    }  transition-opacity w-6 h-6`}
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-4 h-4 me-3 text-main-color animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>{" "}
                  </div>
                </button>
              </motion.div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
});

export default ContactForm;

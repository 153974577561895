import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import headerlayer from "../assets/imgs/headerLayer.png";
import img1 from "../assets/imgs/test1.webp";
import img2 from "../assets/imgs/test2.webp";
export default function SimpleSlider(data) {
  console.log(data.data.length);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: data?.data.length > 1 ? true : false,
    pauseOnHover: false,
    // arrows: false,
    appendDots: (dots) => (
      <div style={{}}>
        <ul className=""> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div className="w-4 h-4 rounded-full bg-[#84a8a9]"></div>
    ),
  };
  return (
    <div>
      <style>{`
		header .slick-dots .slick-active div{
				background:#09585A
			}
      header .slick-dots{
			bottom: -34px;
		}

	[dir='rtl'] header .slick-next {
			left: 60px;
	}
	[dir='rtl'] header .slick-prev {
    right: 60px;
		z-index:20;
}

header .slick-prev:before, header .slick-next:before{
	font-size: 35px !important;
	color: white !important;
}
		
		`}</style>

      <Slider {...settings}>
        {data?.data?.map((el, index) => (
          <div
            key={index}
            className="flex relative justify-center items-start w-full "
          >
            <img
              src={el.image}
              alt=""
              className="w-full h-full object-fill object-center"
              style={{ aspectRatio: "5/2" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

import React, { useEffect, useState } from "react";

import SectionTitle from "./SectionTitle";
import SectionSide from "./SectionSide";
import vision from "../assets/imgs/cam.png";
import message from "../assets/imgs/message.png";
import value1 from "../assets/imgs/value1.png";
import value2 from "../assets/imgs/value2.png";
import value3 from "../assets/imgs/value3.png";
import duty1 from "../assets/imgs/duty1.png";
import duty2 from "../assets/imgs/duty2.png";
import duty3 from "../assets/imgs/duty3.png";
import team1 from "../assets/imgs/team1.png";
import team2 from "../assets/imgs/team2.png";
import team3 from "../assets/imgs/team3.png";
import Value from "./Value";
import Duty from "./Duty";
import Goals from "./Goals";
import AboutSide from "./AboutSide";
import { FormattedMessage } from "react-intl";
import { motion, useAnimation } from "framer-motion";
const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);

const randomDuration = () => Math.random() * 0.07 + 0.23;
const getRandomTransformOrigin = () => {
  const value = (16 + 40 * Math.random()) / 100;
  const value2 = (15 + 36 * Math.random()) / 100;
  return {
    originX: value,
    originY: value2,
  };
};
const vibrateAnimationVariants = {
  start: () => ({
    rotate: 1 % 2 === 0 ? [-1, 1.3, 0] : [1, -1.4, 0],
    transition: {
      delay: getRandomDelay(),
      repeat: Infinity,
      duration: randomDuration(),
    },
  }),
  reset: {
    rotate: 0,
  },
};

function About({ data }) {
  return (
    <div className="mb-20" id="about">
      <motion.div className="flex flex-col justify-center items-center container mx-auto">
        <SectionTitle title={<FormattedMessage id="about" />} />
        <motion.p
          initial={{ opacity: 0, x: "-100px" }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="w-full md:w-2/3 mt-4 text-center md:text-lg"
        >
          {data?.about}
        </motion.p>
      </motion.div>
      <div className="mt-20 flex flex-col justify-center items-center gap-20">
        <SectionSide
          title={<FormattedMessage id="vision" />}
          description={data.vesion}
          reverse={true}
          img={vision}
        />
        <SectionSide
          title={<FormattedMessage id="message" />}
          description={data.message}
          reverse={false}
          img={message}
        />
      </div>
      <div className="mt-20">
        <SectionTitle title={<FormattedMessage id="values" />} />
        <motion.div
          initial={{ opacity: 0, y: "100px" }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="container mx-auto flex flex-col sm:flex-row justify-center sm:justify-around sm:items-start gap-14 sm:gap-0 items-center mt-10"
        >
          <Value
            img={value1}
            title={<FormattedMessage id="Professionalism" />}
            description={data.value_quality}
          />
          <Value
            img={value2}
            title={<FormattedMessage id="Innovation" />}
            description={data.value_innovation}
          />
          <Value
            img={value3}
            title={<FormattedMessage id="quality" />}
            description={data.value_professional}
          />
        </motion.div>
      </div>
      <div className="mt-20">
        <SectionTitle title={<FormattedMessage id="duty" />} />
        <motion.div
          initial={{ opacity: 0, y: "100px" }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="container mx-auto flex flex-col sm:flex-row justify-center sm:justify-around gap-14 sm:gap-0 items-center mt-10 sm:mt-28"
        >
          <div className="sm:bg-[#D4D4D459] w-full rounded-3xl flex flex-col sm:flex-row justify-center sm:justify-around gap-14 sm:gap-0 items-center sm:items-start">
            <Duty
              img={duty1}
              title={<FormattedMessage id="towards_customer" />}
              description={data.duty_customer}
            />
            <Duty
              img={duty2}
              title={<FormattedMessage id="towards_products" />}
              description={data.duty_products}
            />
            <Duty
              img={duty3}
              title={<FormattedMessage id="towards_markets" />}
              description={data.duty_markets}
            />
          </div>
        </motion.div>
      </div>
      <Goals data={data} />
      <div className="mt-20 flex flex-col justify-center items-center gap-20">
        <AboutSide
          title={<FormattedMessage id="our_team" />}
          description={data.team}
          reverse={false}
          img={team1}
        />
        <AboutSide
          title={<FormattedMessage id="our_res" />}
          description={data.responsibilities}
          reverse={true}
          img={team2}
        />
        <AboutSide
          title={<FormattedMessage id="Integrity" />}
          description={data.integrity}
          reverse={false}
          img={team3}
        />
      </div>
    </div>
  );
}

export default About;

import React, { useEffect, useRef } from "react";
import img from "../assets/imgs/product.jpg";
import { motion, useAnimation } from "framer-motion";

function Award({ data }) {
  const textControls = useAnimation();
  const imageControls = useAnimation();
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        textControls.start({ height: "auto" });
        imageControls.start({ height: "100%" });
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div className="w-full flex justify-center items-center">
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: -30 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", duration: 0.8, delay: 0.7 }}
        viewport={{ once: true }}
        onClick={() => {
          textControls.start({ height: 0 });
          imageControls.start({ height: "100%" });
        }}
        onHoverStart={() => {
          textControls.start({ height: 0 });
          imageControls.start({ height: "100%" });
        }}
        onHoverEnd={() => {
          textControls.start({ height: "auto" });
          imageControls.start({ height: "100%" });
        }}
        className="w-full h-[270px] lg:w-4/5 border-4 border-main-color flex flex-col justify-center items-center rounded-3xl overflow-hidden cursor-pointer"
        style={{ boxShadow: "0px 20px 16px -4px #00000082" }}
      >
        <motion.div
          animate={imageControls}
          className="w-full h-[210px]  rounded-b-3xl"
          style={{
            backgroundImage: `url(${data.image})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        />
        <motion.div
          animate={textControls}
          className="w-full group-hover:hidden"
        >
          <h3 className="flex justify-center items-center text-xl text-main-color my-3">
            {data.name}
          </h3>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Award;

import React, { useEffect, useState } from "react";
import logo from "../assets/imgs/logo.png";
import Hamburger from "hamburger-react";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import Switch from "react-switch";
import { GrLanguage } from "react-icons/gr";
import { useLanguage } from "../context/langContext";
import { FormattedMessage } from "react-intl";

function Navbar({ isOpen, setOpen, scrollPosition }) {
  const { language, changeLanguage } = useLanguage();
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const finalHeight = window.innerWidth < 1024 ? 80 : 144;
    setHeight(finalHeight);
  }, []);
  const [checked, setChecked] = useState(language == "en" ? false : true);
  const handleChange = (checked) => {
    setChecked(checked);
    changeLanguage(checked ? "ar" : "en");
  };

  return (
    <nav className="bg-main-color fixed w-full top-0 z-30">
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: height }}
        transition={{ duration: 0.5 }}
        className={`container relative mx-auto flex flex-col justify-center items-center
      ${scrollPosition > 50 ? "h-20 md:h-28 lg:h-32" : "h-20 md:-32 lg:h-36"}
       `}
      >
        <motion.ul
          initial={{ scale: 1.25, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="flex justify-center items-center gap-6 xl:gap-16 text-xl font-bold text-white w-full relative"
        >
          <img
            src={logo}
            alt=""
            className="w-14 lg:w-20 h-14 lg:h-20 absolute start-0 z-[999]"
          />

          <div className="absolute end-0 z-[999] flex justify-center items-center gap-3">
            <Switch
              uncheckedIcon={
                <div className="w-fit h-full flex justify-center items-center mx-auto">
                  <p className="text-main-color text-xs text-center mx-auto">
                    ع
                  </p>
                </div>
              }
              checkedIcon={
                <div className="w-fit h-full flex justify-center items-center px-2">
                  <p className="text-main-color text-xs text-center">En</p>
                </div>
              }
              offColor="#fff"
              onColor="#fff"
              uncheckedHandleIcon={
                <GrLanguage className="text-main-color text-[25px]" />
              }
              checkedHandleIcon={
                <GrLanguage className="text-main-color text-[25px]" />
              }
              onChange={handleChange}
              checked={checked}
            />
            <div className="lg:hidden">
              <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
          </div>

          <Link
            activeStyle={{
              borderBottom: "3px solid white",
            }}
            to="home"
            spy={true}
            offset={-200}
            smooth={true}
            duration={1200}
            className="hover:border-b-[3px] border-white cursor-pointer pb-1 drop-shadow-3xl z-[999] hidden lg:block"
          >
            <FormattedMessage id="home" />
          </Link>
          <Link
            activeStyle={{
              borderBottom: "3px solid white",
            }}
            to="about"
            spy={true}
            smooth={true}
            offset={-150}
            duration={1200}
            className="hover:border-b-[3px] border-white cursor-pointer pb-1 drop-shadow-3xl z-[999] hidden lg:block"
          >
            <FormattedMessage id="about" />
          </Link>
          <Link
            activeStyle={{
              borderBottom: "3px solid white",
            }}
            to="products"
            spy={true}
            smooth={true}
            // offset={21}
            duration={1200}
            className="hover:border-b-[3px] border-white cursor-pointer pb-1 drop-shadow-3xl z-[999] hidden lg:block"
          >
            <FormattedMessage id="products" />
          </Link>
          <Link
            activeStyle={{
              borderBottom: "3px solid white",
            }}
            to="awards"
            spy={true}
            smooth={true}
            offset={-100}
            duration={1200}
            className="hover:border-b-[3px] border-white cursor-pointer pb-1 drop-shadow-3xl z-[999] hidden lg:block"
          >
            <FormattedMessage id="awards" />
          </Link>
          <Link
            activeStyle={{
              borderBottom: "3px solid white",
            }}
            to="brands"
            spy={true}
            smooth={true}
            offset={-150}
            duration={1200}
            className="hover:border-b-[3px] border-white cursor-pointer pb-1 drop-shadow-3xl z-[999] hidden lg:block"
          >
            <FormattedMessage id="brands" />
          </Link>
          <Link
            activeStyle={{
              borderBottom: "3px solid white",
            }}
            to="branches"
            spy={true}
            smooth={true}
            offset={-180}
            duration={1200}
            className="hover:border-b-[3px] border-white cursor-pointer pb-1 drop-shadow-3xl z-[999] hidden lg:block"
          >
            <FormattedMessage id="branches" />
          </Link>
        </motion.ul>
      </motion.div>
    </nav>
  );
}

export default Navbar;

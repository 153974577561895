import React, { useEffect, useRef, useState } from "react";
import map from "../assets/imgs/Map.png";
import SectionTitle from "./SectionTitle";
import ContactUs from "./ContactUs";
import { motion } from "framer-motion";
import BranchesMap from "./Map";

function Branches({ data }) {
  const scrollRef = useRef(null);
  const contactRef = useRef(null);

  const [contactHeight, setContactHeight] = useState();

  useEffect(() => {
    setContactHeight(contactRef?.current?.clientHeight);
  });

  return (
    <div
      ref={scrollRef}
      id="branches"
      className="container pt-20 mx-auto flex flex-col lg:flex-row gap-10 lg:gap-0 justify-center items-center mt-0 sm:mb-52 mb-32"
    >
      <motion.div
        initial={{
          opacity: 0,
          x: -100,
        }}
        viewport={{ once: true }}
        whileInView={{
          transition: { type: "spring", mass: 0.5, delay: 1 },
          opacity: 1,
          x: 0,
        }}
        className={`relative w-full h-full flex justify-center items-stretch`}
      >
        <div className="absolute -top-14">
          <SectionTitle title={"فروعنا"} />
        </div>

        <div className="w-full h-full px-5 md:px-0 flex justify-center items-center">
          <BranchesMap data={data} height={contactHeight} />
        </div>
      </motion.div>
      <motion.div
        initial={{
          opacity: 0,
          x: -100,
        }}
        viewport={{ once: true }}
        whileInView={{
          transition: { type: "spring", mass: 0.5, delay: 1.3 },
          opacity: 1,
          x: 0,
        }}
        className="w-full flex justify-center items-center"
        ref={contactRef}
      >
        <div className="h-[700px] bg-main-color w-full flex flex-col justify-start items-center p-5">
          <div className="h-full w-full">
            <ContactUs ref={scrollRef} />
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Branches;

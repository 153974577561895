import React from "react";
import Navbar from "./Navbar";
import HeaderSlider from "./HeaderSlider";
import Aside from "./Aside";
import { motion } from "framer-motion";

function Header({ isOpen, setOpen, data, contacts }) {
  return (
    <motion.header
      id="home"
      initial={{ x: "-50%", opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="mt-[79px] lg:mt-[144px]"
    >
      <Aside isOpen={isOpen} setOpen={setOpen} contacts={contacts} />

      <HeaderSlider data={data} />
    </motion.header>
  );
}

export default Header;

import React from "react";

function Duty({ img, title, description }) {
  return (
    <div className="relative w-[230px] p-5 flex flex-col justify-center items-center bg-[#D4D4D459] rounded-3xl sm:bg-transparent">
      <div className="sm:absolute sm:top-0 sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-2/3 h-20 w-20 flex justify-center items-center bg-main-color rounded-full">
        <img className="w-12 h-12" src={img} alt={title} />
      </div>
      <div className="text-center flex flex-col justify-center items-center gap-5 sm:mt-5">
        <h3 className="text-lg border-b-4 border-b-main-color pb-1">{title}</h3>
        <p className="text-sm font-extralight min-h-20">{description}</p>
      </div>
    </div>
  );
}

export default Duty;

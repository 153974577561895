import React from "react";
import logo2 from "../assets/imgs/logo2.png";
import {
  PiInstagramLogo,
  PiPhone,
  PiPrinterDuotone,
  PiTwitterLogo,
  PiWhatsappLogo,
} from "react-icons/pi";
import {
  FaFacebook,
  FaLinkedin,
  FaSnapchat,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";

import logo from "../assets/imgs/logo.png";
import { Link } from "react-scroll";
import { FormattedMessage } from "react-intl";
function Footer({ data }) {
  return (
    <>
      <style>{`
			 .clipped{
				clip-path: polygon(0 30%, 100% 0, 100% 100%, 0% 100%);
				
			}
		@media only screen and (max-width: 639px) {
			 .clipped{
				clip-path: none;
				
			}
		}
		`}</style>
      <footer className="relative ">
        <div className="absolute left-0 -top-20 h-full">
          <img src={logo2} className="w-[300px] hidden sm:block" alt="" />
        </div>
        <div className="bg-main-color h-full w-full clipped" style={{}}>
          <div className="w-full h-full flex flex-col-reverse gap-5 sm:gap-0 py-10 sm:py-0 sm:flex-row ltr:sm:flex-row-reverse sm:justify-around items-center  min-h-[65vh] container mx-auto text-white">
            <div className="w-full  flex flex-col justify-center items-center ">
              <div className=" flex flex-col gap-5 justify-center items-end">
                <div className="flex flex-row-reverse gap-5 justify-start items-start">
                  <PiPrinterDuotone size={30} />
                  <div>
                    <p className="drop-shadow-3xl z-[999]">00966126293833</p>
                    <p className="drop-shadow-3xl z-[999]">00966122864359</p>
                  </div>
                </div>
                <div className="flex flex-row-reverse gap-5 justify-start items-start">
                  <PiPhone size={30} />
                  <div>
                    <p className="drop-shadow-3xl z-[999]">00966126293333</p>
                  </div>
                </div>
                <div className="grid grid-cols-4 grid-rows-2 gap-5 justify-start items-start flex-wrap">
                  {data.social_media.map((el, index) => {
                    return (
                      <a href={el.url} key={index} target="_blank">
                        <img src={el.image} alt="" />
                      </a>
                    );
                  })}
                  {/* <PiWhatsappLogo size={30} />
                  <PiInstagramLogo size={30} />
                  <FaXTwitter size={30} />
                  <FaSnapchat size={30} />
                  <FaYoutube size={30} />
                  <FaLinkedin size={30} />
                  <FaFacebook size={30} /> */}
                </div>
              </div>
            </div>
            <div className="w-full  flex flex-col justify-center items-center sm:items-end">
              <div className="w-full flex flex-col justify-center gap-2 items-center z-50">
                <Link
                  to="home"
                  spy={true}
                  offset={-200}
                  smooth={true}
                  duration={1200}
                  className="cursor-pointer text-center z-[999] drop-shadow-3xl "
                >
                  <FormattedMessage id="home" />
                </Link>
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={1200}
                  className="cursor-pointer text-center z-[999] drop-shadow-3xl "
                >
                  <FormattedMessage id="about" />
                </Link>
                <Link
                  to="products"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={1200}
                  className="cursor-pointer text-center z-[999] drop-shadow-3xl "
                >
                  <FormattedMessage id="products" />
                </Link>
                <Link
                  to="awards"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={1200}
                  className="cursor-pointer text-center z-[999] drop-shadow-3xl "
                >
                  <FormattedMessage id="awards" />
                </Link>
                <Link
                  to="brands"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={1200}
                  className="cursor-pointer text-center z-[999] drop-shadow-3xl "
                >
                  <FormattedMessage id="brands" />
                </Link>
                <Link
                  to="branches"
                  spy={true}
                  smooth={true}
                  offset={-180}
                  duration={1200}
                  className="cursor-pointer text-center z-[999] drop-shadow-3xl "
                >
                  <FormattedMessage id="branches" />
                </Link>
              </div>
            </div>
            {/* <div className="w-full  flex flex-col justify-center items-start"></div> */}
            <div className="w-full  flex flex-col justify-center items-center">
              <img src={logo} className="w-[120px]" alt="" />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

import React, { forwardRef } from "react";
import { motion } from "framer-motion";
import { Tooltip } from "react-tooltip";

const Goal = forwardRef(({ title, img, desc , setActive }, ref) => {
  return (
    <motion.div
      className="child z-0 group relative flex flex-col justify-center items-center gap-3 w-[37%] md:w-[37%] h-[37%] md:h-[37%] p-3 border-2 bg-white border-main-color rounded-full cursor-pointer  hover:sm:bg-main-color hover:sm:text-white"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8, delay: 1 }}
      viewport={{ once: true, root: ref.current }}
    >
      <div className="group-hover:sm:hidden h-[32%] w-[32%] absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 md:translate-x-0 md:translate-y-0  md:static flex justify-center items-center bg-main-color rounded-full">
        <img className="w-[60%] " src={img} alt={""} />
      </div>
      <h3 className="group-hover:sm:hidden text-center text-[9px] sm:text-sm">
        {title}
      </h3>
      <p className="hidden group-hover:sm:block text-center text-[4px] md:text-xs">
        {desc}
      </p>
      <button
      onClick={()=>{setActive({desc})}}
        className="absolute top-0 bottom-0 left-0 right-0 sm:hidden max-w-52"
        // data-tooltip-id="my-tooltip"
        // data-tooltip-content={desc}
        // data-tooltip-place="top"
      ></button>
      {/* 
      <Tooltip id="my-tooltip" className="max-w-52 z-10" /> */}
    </motion.div>
  );
});

export default Goal;

import React from "react";
import SectionTitle from "./SectionTitle";
import Award from "./Award";
import { FormattedMessage } from "react-intl";

function Awards({ data }) {
  return (
    <div id="awards" className="my-10 mb-36 py-10">
      <SectionTitle title={<FormattedMessage id="awards" />} />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 container mx-auto mt-10 gap-10 gap-y-16 ">
        {data.map((el, index) => {
          return <Award key={index} data={el} />;
        })}
      </div>
    </div>
  );
}

export default Awards;

import React, { createContext, useState, useContext } from "react";

const LanguageContext = createContext("");

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setLanguage] = useState("ar");

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider
      value={{ language: currentLanguage, changeLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

import React, { useState } from "react";
import marker from "../assets/imgs/marker.png";
import {
  APIProvider,
  InfoWindow,
  Map,
  Marker,
} from "@vis.gl/react-google-maps";
import { MarkerWithInfowindow } from "./MaarkerWithInfo";
function BranchesMap({ data, height }) {
  const [infoWindowOpen, setInfoWindowOpen] = useState(true);
  const position = { lat: 25.87281641704653, lng: 44.288478863321124 };
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const Markers = () => {
    data.map((el, index) => (
      <Marker key={index} position={{ lat: el.lat, lng: el.long }} />
    ));
  };

  return (
    <>
      <style>{`
    .map {
      height: ${height}px;
    }
    
    @media only screen and (max-width: 1023px) {
      .map {
        height: 300px;
      }
    }
    `}</style>
      <div className={`map`} style={{ width: "100%" }}>
        <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
          <Map
            defaultCenter={position}
            defaultZoom={5}
            gestureHandling={"greedy"}
            disableDefaultUI={true}
          >
            <Markers />
            {/* <Marker position={{ lat: +data[0].lat, lng: +data[0].long }} /> */}
            {data.map((el, index) => {
              return (
                <>
                  {/* <Marker
                    key={index}
                    position={{ lat: +el.lat, lng: +el.long }}
                  /> */}
                  <MarkerWithInfowindow
                    position={{ lat: +el.lat, lng: +el.long }}
                    info={el.info}
                  />
                </>
              );
            })}
          </Map>
        </APIProvider>
      </div>
    </>
  );
}

export default BranchesMap;

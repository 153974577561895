import React from "react";
import userImg from "../assets/imgs/userImg.png";
import { motion } from "framer-motion";
import { FormattedMessage } from "react-intl";
import { FaWhatsapp } from "react-icons/fa";
import { PiPhoneFill } from "react-icons/pi";
import { GrClose } from "react-icons/gr";

function FloatSection({
  data,
  isHovered,
  setIsHovered,
  scrollPosition,
  language,
}) {
  return (
    <motion.div
      initial={{ x: "-10%", opacity: 0 }}
      whileInView={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
      className={`w-full flex bg-white h-0 justify-between items-end px-2 sm:px-5 mx-auto fixed z-10 bottom-10  `}
    >
      <motion.div className="group flex flex-col-reverse items-start sm:flex-row justify-center sm:items-center">
        <div className="min-w-10 sm:min-w-16 min-h-10 sm:min-h-16">
          <motion.div
            transition={{ duration: 1, repeat: Infinity }}
            className={`blob w-12 h-12  flex justify-center items-center rounded-full z-[999]`}
            layout
          >
            <motion.img
              src={userImg}
              className="w-12 h-12  rounded-full"
              alt=""
            />
          </motion.div>
        </div>
        <motion.div
          layout
          className=" w-0 h-12 overflow-visible flex gap-2 justify-center items-center group-hover:border-main-color group-hover:w-fit text-black"
          // className="bubble right "
        >
          <div
            className={`bubble ${
              language == "ar" ? "right" : "left"
            }  px-3 py-2 group-hover:flex justify-center items-center gap-2 hidden `}
          >
            <FormattedMessage id="how_to_help" />
            <a
              href={data.contacts.info.whatsapp}
              target="_blank"
              className="bg-[#00a884] p-2 rounded-full"
            >
              <FaWhatsapp className="text-white text-[25px]" />
            </a>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        // onHoverStart={() => setIsHovered(true)}
        // onHoverEnd={() => setIsHovered(false)}
        // layout
        className={`p-2 border-main-color rounded-full relative flex flex-col-reverse items-center gap-1 z-[999]`}
      >
        {!isHovered ? (
          <motion.div onClick={() => setIsHovered(true)}>
            <div className="w-12 z-[999] cursor-pointer h-12 flex rounded-full justify-center items-center bg-main-color">
              <PiPhoneFill className="" size={20} color="white" />
            </div>
          </motion.div>
        ) : (
          <motion.div onClick={() => setIsHovered(false)}>
            <div className="w-12 z-[999] cursor-pointer h-12 flex rounded-full justify-center items-center bg-main-color">
              <GrClose color="white" size={20} />
            </div>
          </motion.div>
        )}

        {isHovered &&
          data.contacts.social_media.map((el, index) => (
            <motion.div
              layout
              onClick={() => setIsHovered(false)}
              key={index}
              initial={{ y: 10, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { delay: 2 * (index / 10) },
              }}
              exit={{
                opacity: 0,
                y: 10,
                transition: { delay: 2 * (index / 10) },
              }}
              whileHover={{ rotate: 360, transition: { delay: 0 } }}
              // style={{ pointerEvents: "none" }}
              className="z-[888]"
            >
              <a
                target="_blank"
                href={el.url}
                className="flex z-[999] w-10 cursor-pointer h-10 rounded-full justify-center items-center"
              >
                <img src={el.image} className="w-full h-full" />
              </a>
            </motion.div>
          ))}
      </motion.div>
    </motion.div>
  );
}

export default FloatSection;

import React from "react";

function Value({ img, title, description }) {
  return (
    <div className="flex flex-col gap-5 justify-start items-center w-[200px] group cursor-pointer">
      <div className="flex flex-col justify-start items-center border-4 group-hover:bg-main-color group-hover:text-white transition-all duration-500 border-main-color px-2 pb-9 pt-2 rounded-full gap-5">
        <div className="bg-main-color transition-all duration-500 group-hover:bg-[#D4D4D459] p-5 rounded-full">
          <img className="w-24 h-2w-24" src={img} alt={title} />
        </div>
        <h4 className="text-lg">{title}</h4>
      </div>
      <p className="text-center text-sm font-extralight">{description}</p>
    </div>
  );
}

export default Value;

import { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header";
import About from "./components/About";
import Brands from "./components/Brands";
import Branches from "./components/Branches";
import headerlayer from "./assets/imgs/headerLayer.png";
import Footer from "./components/Footer";
import Products from "./components/Products";
import Awards from "./components/Awards";
import Navbar from "./components/Navbar";
import { FormattedMessage, IntlProvider } from "react-intl";
import en from "./lang/en.json";
import ar from "./lang/ar.json";
import { LanguageProvider, useLanguage } from "./context/langContext";
import { PiPhoneFill } from "react-icons/pi";
import { motion, useAnimation } from "framer-motion";
import axios from "axios";
import { GrClose } from "react-icons/gr";
import { Toaster } from "react-hot-toast";
import Map from "./components/Map";
import { FaWhatsapp } from "react-icons/fa";
import intro from "./assets/شعار الانتظار لموقع الحازمي.gif";
import FloatSection from "./components/FloatSection";
const messages = {
  en,
  ar,
};

function getDirection(locale) {
  if (locale === "ar") {
    return "rtl";
  }

  return "ltr";
}
function App() {
  const [data, setData] = useState(null);
  const { language, changeLanguage } = useLanguage();
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    document.documentElement.classList.toggle("no-scroll", isOpen);
    return () => {
      document.documentElement.classList.remove("no-scroll");
    };
  }, [isOpen]);

  useEffect(() => {
    axios
      .get(`https://admin-is-trator.alhazmie.com/api/home`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [language]);

  useEffect(() => {
    const handleScroll = (e) => {
      const scrollPosition = window.scrollY;
      setScrollPosition(scrollPosition);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Toaster />
      {data ? (
        <div className="relative" dir={getDirection(language)}>
          {/* <div className="absolute top-0 z-50 h-screen w-screen">
          <img
            src={headerlayer}
            className="h-full w-screen -translate-y-[200px]"
            alt=""
          />
        </div> */}
          <Navbar
            isOpen={isOpen}
            setOpen={setOpen}
            scrollPosition={scrollPosition}
          />

          <Header
            contacts={data.contacts}
            data={data.slider}
            isOpen={isOpen}
            setOpen={setOpen}
          />
          <br />
          <br />
          <br />
          <FloatSection
            language={language}
            data={data}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            scrollPosition={scrollPosition}
          />
          <About data={data.content} />
          <Products data={data.catalogue} />
          <Awards data={data.awards} />
          <Brands data={data.partners} />
          <Branches data={data.branches} />
          <Footer data={data.contacts} />
        </div>
      ) : (
        <div className="h-screen w-screen flex justify-center items-center">
          <img className=" w-1/3" src={intro} />
        </div>
      )}
    </IntlProvider>
  );
}

export default App;

import React, { useEffect, useRef } from "react";
import img from "../assets/imgs/product.jpg";
import { motion, useAnimation } from "framer-motion";
function SingleProduct({ data }) {
  const textControls = useAnimation();
  const imageControls = useAnimation();
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        textControls.start({ width: "100%" });
        imageControls.start({ width: "100%" });
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <motion.div
      initial={{ opacity: 0, y: -30 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, delay: 0.7 }}
      viewport={{ once: true }}
      className="w-full h-[130px] sm:h-[150px] lg:h-[170px] border border-main-color flex justify-center items-center rounded-3xl overflow-hidden cursor-pointer"
      ref={ref}
      onClick={() => {
        textControls.start({ width: 0 });
        imageControls.start({ width: "100%" });
      }}
      onHoverStart={() => {
        textControls.start({ width: 0 });
        imageControls.start({ width: "100%" });
      }}
      onHoverEnd={() => {
        textControls.start({ width: "100%" });
        imageControls.start({ width: "100%" });
      }}
    >
      <motion.div className="w-full group-hover:hidden" animate={textControls}>
        {" "}
        <h3 className="flex justify-center items-center text-xl text-main-color">
          {data.name}
        </h3>
      </motion.div>
      <motion.div
        className="w-full h-full rounded-3xl"
        animate={imageControls}
        transition={{ times: "inertia" }}
        style={{
          backgroundImage: `url(${data.image})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/* <motion.img src={img} alt="" />{" "} */}
      </motion.div>
    </motion.div>
  );
}

export default SingleProduct;

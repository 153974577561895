import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-scroll";
import Hamburger from "hamburger-react";

function Aside({ isOpen, setOpen, contacts }) {
  return (
    <aside
      className={` ${
        isOpen ? "fixed border-t-2" : "hidden"
      } lg:hidden flex justify-center items-start bg-main-color w-full h-[100dvh] z-50 transition-all duration-1000`}
    >
      {/* <Hamburger toggled={isOpen} toggle={setOpen} /> */}
      {/* <div className="h-[128px] bg-main-color"></div> */}
      <div className="w-full h-[calc(100dvh_-_128px)] flex flex-col gap-10 justify-center items-center pt-20">
        <ul className="flex flex-col justify-center items-center gap-5 text-white">
          <Link
            onClick={() => setOpen(false)}
            to="home"
            spy={true}
            offset={-200}
            smooth={true}
            duration={1200}
            className="cursor-pointer drop-shadow-3xl z-[999]"
          >
            <FormattedMessage id="home" />
          </Link>
          <Link
            onClick={() => setOpen(false)}
            to="about"
            spy={true}
            smooth={true}
            offset={-150}
            duration={1200}
            className="cursor-pointer drop-shadow-3xl z-[999]"
          >
            <FormattedMessage id="about" />
          </Link>
          <Link
            onClick={() => setOpen(false)}
            to="products"
            spy={true}
            smooth={true}
            // offset={-70}
            duration={1200}
            className="cursor-pointer drop-shadow-3xl z-[999]"
          >
            <FormattedMessage id="products" />
          </Link>
          <Link
            onClick={() => setOpen(false)}
            to="awards"
            spy={true}
            smooth={true}
            offset={-100}
            duration={1200}
            className="cursor-pointer drop-shadow-3xl z-[999]"
          >
            <FormattedMessage id="awards" />
          </Link>
          <Link
            onClick={() => setOpen(false)}
            to="brands"
            spy={true}
            smooth={true}
            offset={-150}
            duration={1200}
            className="cursor-pointer drop-shadow-3xl z-[999]"
          >
            <FormattedMessage id="brands" />
          </Link>
          <Link
            onClick={() => setOpen(false)}
            to="branches"
            spy={true}
            smooth={true}
            offset={-180}
            duration={1200}
            className="cursor-pointer drop-shadow-3xl z-[999]"
          >
            <FormattedMessage id="branches" />
          </Link>
        </ul>
        <div className="grid grid-cols-4 grid-rows-2 gap-5 justify-center items-start flex-wrap">
          {contacts.social_media.map((el, index) => {
            return (
              <a href={el.url} key={index} target="_blank">
                <img src={el.image} alt="" />
              </a>
            );
          })}
        </div>
      </div>
    </aside>
  );
}

export default Aside;
